$(document).ready(function(){

  $('#slider .owl-carousel').owlCarousel({
    animateOut:'fadeOut',
    animateIn:'fadeIn',
    loop:true,
    margin:0,
    dots:false,
    autoplay:true,
    autoplayTimeout:5000,
    items:1,
  })

  //открыть меню --начало--
  /*  $('#menubutton').click(function(){
      $('div#menu').css({"left":"0"})
      $('div#shadow').css({"z-index":"998", "opacity":"1"})
    });
  //открыть меню --конец--

  //закрыть меню --начало--
    $('#menuclose').click(function(){
      var client_w=document.body.clientWidth;
      $('div#menu').css({"left":"-250px"})
      $('div#shadow').css({"z-index":"-1", "opacity":"0"})
    });

    $('div#shadow').click(function(){
      var client_w=document.body.clientWidth;
      $('div#menu').css({"left":"-250px"})
      $('div#shadow').css({"z-index":"-1", "opacity":"0"})
    });*/
  //закрыть меню --конец--

  $('.go_to').click( function(){
    var scroll_el = $(this).attr('href');
    if ($(scroll_el).length != 0) {
      $('html, body').animate({ scrollTop: $(scroll_el).offset().top - 0 }, 500);
    }
    return false;
  });

})
